import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import {
  Layout,
  getParentLayout
} from '@/utils/routerHelper'

export const constantRouterMap: AppRouteRecordRaw[] =
  [
    {
      path: '/',
      component: Layout,
      redirect: '/user',
      name: 'Root',
      meta: {
        hidden: true
      }
    },
    {
      path: '/redirect',
      component: Layout,
      name: 'Redirect',
      children: [
        {
          path: '/redirect/:path(.*)',
          name: 'Redirect',
          component: () =>
            import(
              '@/views/Redirect/Redirect.vue'
            ),
          meta: {}
        }
      ],
      meta: {
        hidden: true,
        noTagsView: true
      }
    },
    {
      path: '/login',
      component: () =>
        import('@/views/Login/Login.vue'),
      name: 'Login',
      meta: {
        hidden: true,
        title: '登录',
        noTagsView: true
      }
    },
    {
      path: '/404',
      component: () =>
        import('@/views/Error/404.vue'),
      name: 'NoFind',
      meta: {
        hidden: true,
        title: '404',
        noTagsView: true
      }
    }
  ]

// getParentLayout
export const asyncRouterMap: AppRouteRecordRaw[] =
  [
    {
      path: '/overview',
      name: 'Overview',
      component: Layout,
      redirect: '/overview/list',
      meta: {
        title: '平台概览'
      },
      children: [
        {
          path: 'list',
          name: 'OverviewList',
          component: () =>
            import('@/views/Overview/index.vue'),
          meta: {
            title: '平台概况'
          }
        }
      ]
    },
    {
      path: '/statement',
      name: 'Statement',
      component: Layout,
      redirect: '/statement/overview',
      meta: {
        title: '统计报表'
      },
      children: [
        {
          path: 'overview',
          name: 'StatementOverviewList',
          component: () =>
            import(
              '@/views/Statement/overview.vue'
            ),
          meta: {
            title: '数据概览'
          }
        },
        {
          path: 'promote',
          name: 'StatementPromoteList',
          component: () =>
            import(
              '@/views/Statement/promote.vue'
            ),
          meta: {
            title: '渠道数据'
          }
        },
        {
          path: 'keepStat',
          name: 'StatementKeepStatList',
          component: () =>
            import(
              '@/views/Statement/keepStat.vue'
            ),
          meta: {
            title: '留存数据'
          }
        },
        {
          path: 'keepStatLtv',
          name: 'StatementKeepStatLtvList',
          component: () =>
            import(
              '@/views/Statement/keepStatLtv.vue'
            ),
          meta: {
            title: '付费LTV'
          }
        },
        {
          path: 'behaviorStat',
          name: 'StatementBehaviorStatList',
          component: () =>
            import(
              '@/views/Statement/behaviorStat.vue'
            ),
          meta: {
            title: '行为数据'
          }
        }
      ]
    },
    {
      path: '/user',
      name: 'User',
      component: Layout,
      redirect: '/user/list',
      meta: {
        title: '用户列表'
      },
      children: [
        {
          path: 'list',
          name: 'UserList',
          component: () =>
            import('@/views/User/index.vue'),
          meta: {
            title: '用户列表'
          }
        },
        {
          path: 'list-detail',
          name: 'UserDetail',
          component: () =>
            import('@/views/User/detail.vue'),
          meta: {
            title: '用户详情',
            noTagsView: true,
            noCache: true,
            hidden: true,
            canTo: true,
            activeMenu: '/user/list'
          }
        },
        {
          path: 'BanUser',
          name: 'BanUser',
          component: () =>
            import('@/views/User/banUser.vue'),
          meta: {
            title: '封禁列表'
          }
        },
        {
          path: 'RechargeRank',
          name: 'RechargeRank',
          component: () =>
            import('@/views/User/rank.vue'),
          meta: {
            title: '充值排行'
          }
        },
        {
          path: 'UserLogin',
          name: 'UserLogin',
          component: () =>
            import('@/views/User/login.vue'),
          meta: {
            title: '登录记录'
          }
        },
        {
          path: 'UserReport',
          name: 'UserReport',
          component: () =>
            import('@/views/User/report.vue'),
          meta: {
            title: '举报记录'
          }
        },
        {
          path: 'UserFaceVerify',
          name: 'UserFaceVerify',
          component: () =>
            import('@/views/User/faceVerify.vue'),
          meta: {
            title: '实名认证'
          }
        }
      ]
    },
    {
      path: '/monitoring',
      name: 'Monitoring',
      component: Layout,
      redirect: '/monitoring/im',
      meta: {
        title: '风控管理'
      },
      children: [
        {
          path: 'im',
          name: 'MonitoringIm',
          component: () =>
            import('@/views/Monitoring/im.vue'),
          meta: {
            title: 'IM消息监控'
          }
        },
        {
          path: 'word',
          name: 'MonitoringWord',
          component: () =>
            import('@/views/Monitoring/word.vue'),
          meta: {
            title: '词汇排行'
          }
        },
        // {
        //   path: 'divisor',
        //   name: 'MonitoringDivisor',
        //   component: () =>
        //     import(
        //       '@/views/Monitoring/divisor.vue'
        //     ),
        //   meta: {
        //     title: '审核因子'
        //   }
        // },
        // {
        //   path: 'record',
        //   name: 'MonitoringRecord',
        //   component: () =>
        //     import(
        //       '@/views/Monitoring/record.vue'
        //     ),
        //   meta: {
        //     title: '审核纪录'
        //   }
        // },
        // {
        //   path: 'user',
        //   name: 'MonitoringUser',
        //   component: () =>
        //     import('@/views/Monitoring/user.vue'),
        //   meta: {
        //     title: '审核名单'
        //   }
        // },
        {
          path: 'user',
          name: 'MonitoringUser',
          component: () =>
            import(
              '@/views/Monitoring/index.vue'
            ),
          meta: {
            title: '审核名单'
          }
        }
      ]
    },
    {
      path: '/room',
      name: 'Room',
      component: Layout,
      redirect: '/room/list',
      meta: {
        title: '房间列表管理'
      },
      children: [
        {
          path: 'roomlist',
          name: 'RoomList',
          component: () =>
            import('@/views/Room/index.vue'),
          meta: {
            title: '房间列表'
          }
        },
        {
          path: 'manage',
          name: 'RoomManage',
          component: () =>
            import('@/views/Room/manage.vue'),
          meta: {
            title: '房间管理员记录'
          }
        }
      ]
    },
    {
      path: '/cp',
      name: 'Cp',
      component: Layout,
      redirect: '/cp/list',
      meta: {
        title: 'CP关系管理'
      },
      children: [
        {
          path: 'list',
          name: 'CpList',
          component: () =>
            import('@/views/Cp/index.vue'),
          meta: {
            title: 'CP关系'
          }
        },
        {
          path: 'remove',
          name: 'CpRemove',
          component: () =>
            import('@/views/Cp/relieve.vue'),
          meta: {
            title: 'CP关系解除'
          }
        }
      ]
    },
    {
      path: '/cpspace',
      name: 'CpSpace',
      component: Layout,
      redirect: '/cpspace/list',
      meta: {
        title: 'CP空间管理'
      },
      children: [
        {
          path: 'list',
          name: 'CpSpaceList',
          component: () =>
            import('@/views/CpSpace/index.vue'),
          meta: {
            title: 'CP空间'
          }
        },
        {
          path: 'relieve',
          name: 'CpSpaceRelieveList',
          component: () =>
            import('@/views/CpSpace/relieve.vue'),
          meta: {
            title: 'CP空间解除'
          }
        },
        {
          path: 'task',
          name: 'CpTask',
          component: () =>
            import('@/views/CpSpace/task.vue'),
          meta: {
            title: '任务列表'
          }
        },
        {
          path: 'lovelevel',
          name: 'lovelevelList',
          component: () =>
            import(
              '@/views/CpSpace/loveLevel.vue'
            ),
          meta: {
            title: '恩爱等级配置'
          }
        },
        {
          path: 'ratio',
          name: 'ratio',
          component: () =>
            import('@/views/CpSpace/ratio.vue'),
          meta: {
            title: '恩爱等级加速配置'
          }
        },
        {
          path: 'process',
          name: 'process',
          component: () =>
            import('@/views/CpSpace/process.vue'),
          meta: {
            title: 'cp纪念事迹'
          }
        }
      ]
    },
    {
      path: '/sweet',
      name: 'sweetCollection',
      component: Layout,
      redirect: '/sweet/list',
      meta: {
        title: '甜蜜收藏管理'
      },
      children: [
        {
          path: 'list',
          name: 'sweetCollectionList',
          component: () =>
            import('@/views/Sweet/index.vue'),
          meta: {
            title: '甜蜜收藏记录'
          }
        },
        {
          path: 'record',
          name: 'sweetCollectionSend',
          component: () =>
            import('@/views/Sweet/send.vue'),
          meta: {
            title: '赠送礼物记录'
          }
        },
        {
          path: 'gift',
          name: 'sweetCollectionGift',
          component: () =>
            import('@/views/Sweet/gift.vue'),
          meta: {
            title: '藏品礼物配置'
          }
        },
        {
          path: 'level',
          name: 'sweetCollectionLevel',
          component: () =>
            import('@/views/Sweet/level.vue'),
          meta: {
            title: '藏品等级配置'
          }
        },
        {
          path: 'reward',
          name: 'sweetCollectionReward',
          component: () =>
            import('@/views/Sweet/reward.vue'),
          meta: {
            title: '藏品奖励配置'
          }
        }
      ]
    },
    {
      path: '/summer',
      name: 'summerCollection',
      component: Layout,
      redirect: '/summer/list',
      meta: {
        title: '夏日浪漫管理'
      },
      children: [
        {
          path: 'list',
          name: 'summerList',
          component: () =>
            import(
              '@/views/SummerRomance/gift.vue'
            ),
          meta: {
            title: '夏日浪漫配置管理'
          }
        },
        {
          path: 'record',
          name: 'summerRecord',
          component: () =>
            import(
              '@/views/SummerRomance/index.vue'
            ),
          meta: {
            title: '夏日浪漫记录列表'
          }
        }
      ]
    },
    {
      path: '/application',
      name: 'Application',
      component: Layout,
      redirect: '/application/app',
      meta: {
        title: '应用管理'
      },
      children: [
        {
          path: 'app',
          name: 'App',
          component: () =>
            import('@/views/Application/app.vue'),
          meta: {
            title: '应用列表'
          }
        },
        {
          path: 'promote',
          name: 'Promote',
          component: () =>
            import(
              '@/views/Application/promote.vue'
            ),
          meta: {
            title: '应用渠道'
          }
        },
        {
          path: 'versions',
          name: 'Versions',
          component: () =>
            import(
              '@/views/Application/versions.vue'
            ),
          meta: {
            title: '应用版本'
          }
        },
        {
          path: 'appftprecord',
          name: 'AppftpRecord',
          component: () =>
            import(
              '@/views/Application/appftprecord.vue'
            ),
          meta: {
            title: '应用母包'
          }
        },
        {
          path: 'audit',
          component: getParentLayout(),
          redirect: '/application/audit/robot',
          name: 'AppftpAudit',
          meta: {
            title: '审核素材'
          },
          children: [
            {
              path: 'robot',
              name: 'applicationRobot',
              component: () =>
                import(
                  '@/views/Application/robot.vue'
                ),
              meta: {
                title: '机器人模版'
              }
            },
            {
              path: 'room',
              name: 'applicationRoom',
              component: getParentLayout(),
              redirect:
                '/application/audit/room/rooms',
              meta: {
                title: '语聊房间'
              },
              children: [
                {
                  path: 'rooms',
                  name: 'applicationRooms',
                  component: () =>
                    import(
                      '@/views/Application/room.vue'
                    ),
                  meta: {
                    title: '房间配置'
                    // noTagsView: true,
                    // hidden: true,
                    // activeMenu:
                    //   '/application/audit/room/rooms'
                  }
                },
                {
                  path: 'projectDetail',
                  name: 'applicationProjectDetail',
                  component: () =>
                    import(
                      '@/views/Application/projectDetail.vue'
                    ),
                  meta: {
                    title: '观众配置',
                    // noTagsView: true,
                    noCache: true,
                    hidden: true,
                    canTo: true,
                    activeMenu:
                      '/application/audit/room/rooms'
                  }
                }
              ]
            },
            {
              path: 'plan',
              name: 'applicationPlan',
              component: getParentLayout(),
              redirect:
                '/application/audit/plan/plans',
              meta: {
                title: '素材方案'
              },
              children: [
                {
                  path: 'plans',
                  name: 'applicationPlans',
                  component: () =>
                    import(
                      '@/views/Application/plan.vue'
                    ),
                  meta: {
                    title: '审核方案',
                    noCache: true
                    // noTagsView: true,
                    // hidden: true,
                    // activeMenu:
                    //   '/application/audit/plan/plans'
                  }
                },
                {
                  path: 'planDetail',
                  name: 'applicationplanDetail',
                  component: () =>
                    import(
                      '@/views/Application/planDetail.vue'
                    ),
                  meta: {
                    title: '方案配置',
                    // noTagsView: true,
                    noCache: true,
                    hidden: true,
                    canTo: true,
                    activeMenu:
                      '/application/audit/plan/plans'
                  }
                }
              ]
            }
          ]
        }
        // {
        //   path: 'appftp',
        //   name: 'Appftp',
        //   component: () =>
        //     import(
        //       '@/views/Application/appftp.vue'
        //     ),
        //   meta: {
        //     title: '应用母包'
        //   }
        // }
      ]
    },
    {
      path: '/platform',
      name: 'Platform',
      component: Layout,
      redirect: '/platform/recharge/price',
      meta: {
        title: '平台配置'
      },
      children: [
        {
          path: 'recharge',
          component: getParentLayout(),
          redirect: '/platform/recharge/price',
          name: 'Form',
          meta: {
            title: '充值配置'
          },
          children: [
            {
              path: 'pay',
              name: 'payList',
              component: () =>
                import(
                  '@/views/Platform/pay.vue'
                ),
              meta: {
                title: '支付配置'
              }
            },
            {
              path: 'price',
              name: 'PriceList1',
              component: () =>
                import(
                  '@/views/Platform/price.vue'
                ),
              meta: {
                title: '充值档位'
              }
            },
            {
              path: 'firstRecharge',
              name: 'FirstRecharge',
              component: () =>
                import(
                  '@/views/Platform/firstRecharge.vue'
                ),
              meta: {
                title: '首充配置'
              }
            }
          ]
        },
        {
          path: 'sms',
          component: getParentLayout(),
          redirect: '/sms/mode',
          name: 'Sms',
          meta: {
            title: '短信配置'
          },
          children: [
            {
              path: 'mode',
              name: 'SmsMode',
              component: () =>
                import('@/views/Sms/mode.vue'),
              meta: {
                title: '短信模板'
              }
            },
            {
              path: 'log',
              name: 'SmsLog',
              component: () =>
                import('@/views/Sms/log.vue'),
              meta: {
                title: '短信记录'
              }
            }
          ]
        },
        {
          path: 'exchangecard',
          name: 'Exchangecard',
          component: () =>
            import(
              '@/views/Platform/exchangecard.vue'
            ),
          meta: {
            title: '积分兑换卡配置'
          }
        },
        {
          path: 'laber',
          name: 'Laber',
          component: () =>
            import('@/views/Platform/laber.vue'),
          meta: {
            title: '标签管理'
          }
        },
        {
          path: 'intimacyLev',
          name: 'IntimacyLev',
          component: () =>
            import(
              '@/views/Platform/intimacyLev.vue'
            ),
          meta: {
            title: 'cp等级配置'
          }
        },
        {
          path: 'bulletinReward',
          name: 'BulletinReward',
          component: () =>
            import(
              '@/views/Platform/bulletinReward.vue'
            ),
          meta: {
            title: '官宣奖励配置'
          }
        },
        {
          path: 'banner',
          name: 'Banner',
          component: () =>
            import('@/views/Platform/banner.vue'),
          meta: {
            title: '轮播图配置'
          }
        },
        {
          path: 'agreement',
          name: 'Agreement',
          component: () =>
            import(
              '@/views/Platform/agreement.vue'
            ),
          meta: {
            title: '协议配置'
          }
        },
        {
          path: 'dict',
          name: 'Dict',
          component: () =>
            import('@/views/Platform/dict.vue'),
          meta: {
            title: '字典配置'
          }
        },
        {
          path: 'dictDetail',
          name: 'DictDetail',
          component: () =>
            import(
              '@/views/Platform/dictDetail.vue'
            ),
          meta: {
            title: '字典详情配置',
            noTagsView: true,
            noCache: true,
            hidden: true,
            canTo: true,
            activeMenu: '/platform/dict'
          }
        }
      ]
    },
    {
      path: '/union',
      name: 'Union',
      component: Layout,
      redirect: '/union/list',
      meta: {
        title: '家族管理'
      },
      children: [
        {
          path: 'list',
          name: 'UnionList',
          component: () =>
            import('@/views/Union/index.vue'),
          meta: {
            title: '家族列表'
          }
        },
        {
          path: 'account',
          name: 'UnionAccount',
          component: () =>
            import('@/views/Union/account.vue'),
          meta: {
            title: '家族流水'
          }
        },
        {
          path: 'rate',
          name: 'UnionRate',
          component: () =>
            import('@/views/Union/rate.vue'),
          meta: {
            title: '家族收益'
          }
        },
        {
          path: 'ascription',
          name: 'UnionAscription',
          component: () =>
            import(
              '@/views/Union/ascription.vue'
            ),
          meta: {
            title: '家族拉新'
          }
        }
      ]
    },
    {
      path: '/finance',
      name: 'Finance',
      component: Layout,
      redirect: '/finance/recharge',
      meta: {
        title: '财务管理'
      },
      children: [
        {
          path: 'recharge',
          name: 'FinanceRechargeList',
          component: () =>
            import(
              '@/views/Finance/recharge.vue'
            ),
          meta: {
            title: '充值订单'
          }
        },
        {
          path: 'withdraw',
          name: 'FinanceWithdrawList',
          component: () =>
            import(
              '@/views/Finance/withdraw.vue'
            ),
          meta: {
            title: '个人提现'
          }
        },
        {
          path: 'list',
          name: 'PropList',
          component: () =>
            import('@/views/Finance/gift.vue'),
          meta: {
            title: '礼物订单'
          }
        },
        {
          path: 'diamond',
          name: 'FinanceDiamondList',
          component: () =>
            import('@/views/Finance/diamond.vue'),
          meta: {
            title: '货币加减'
          }
        }
      ]
    },
    {
      path: '/prop',
      name: 'Prop',
      component: Layout,
      redirect: '/prop/list',
      meta: {
        title: '道具管理'
      },
      children: [
        {
          path: 'gift',
          name: 'PropGift',
          component: () =>
            import('@/views/Prop/gift.vue'),
          meta: {
            title: '礼物配置'
          }
        },
        {
          path: 'dress',
          name: 'DressList',
          component: () =>
            import('@/views/Prop/dress.vue'),
          meta: {
            title: '装扮配置'
          }
        }
      ]
    },
    {
      path: '/game',
      name: 'game',
      component: Layout,
      redirect: '/game/list',
      meta: {
        title: '游戏管理'
      },
      children: [
        {
          path: 'gameConfig',
          name: 'gameConfig',
          component: () =>
            import('@/views/Game/gameConfig.vue'),
          meta: {
            title: '小游戏列表'
          }
        }
        // {
        //   path: 'smallGame',
        //   name: 'smallGame',
        //   component: () =>
        //     import('@/views/Game/smallGame.vue'),
        //   meta: {
        //     title: '小游戏列表'
        //   }
        // }
      ]
    }
  ]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = [
    'Redirect',
    'Login',
    'NoFind',
    'Root'
  ]
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (
      name &&
      !resetWhiteNameList.includes(name as string)
    ) {
      router.hasRoute(name) &&
        router.removeRoute(name)
    }
  })
}

export const setupRouter = (
  app: App<Element>
) => {
  app.use(router)
}

export default router
